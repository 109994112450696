import { v4 as uuidv4 } from "uuid";
import debounce from "lodash/debounce";

let socket, appInitData, favIconUrl;
let windowMessageEvtListener = false;

const selectorNameElementMap = {};

const logger = {
  ...console,
  info: (...props) => {
    console.info(new Date().toISOString(), ...props);
  },
  error: (...props) => {
    console.info(new Date().toISOString(), ...props);
  },
};

const isSignatureValid = async (data) => {
  const { v24Signature, signature, ...rest } = data;
  const payload = JSON.stringify(rest);

  try {
    const key = await crypto.subtle.importKey(
      "spki",
      Uint8Array.from(
        atob(process.env.BRAIN_MESSAGES_SIGNING_PUBLIC_KEY),
        (c) => c.charCodeAt(0)
      ),
      { name: "RSASSA-PKCS1-v1_5", hash: "SHA-256" },
      false,
      ["verify"]
    );

    return crypto.subtle.verify(
      "RSASSA-PKCS1-v1_5",
      key,
      Uint8Array.from(atob(v24Signature.replace("24-", "")), (c) =>
        c.charCodeAt(0)
      ),
      new TextEncoder().encode(payload)
    );
  } catch (e) {
    logger.error("Signature verification error", e);
    return false;
  }
};

async function _digestMessage(message) {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hashBuffer = await crypto.subtle.digest("SHA-1", data);

  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");

  return hashHex.substring(0, 36);
}

/* 
Helper function to sanitize value.
It allows Letters, numbers and some special characters.
Characters allowed: !@#$%^&*()_+-[]{};:'"\|,.<>/?
 */
const sanitizeValue = (value) => {
  if (typeof value === "number") {
    return value;
  }

  if (!value) {
    return null;
  }

  // Remove any HTML tags and attributes
  const strippedInput = value.replace(/(<([^>]+)>)/gi, "");

  // Allow only specific characters
  const allowedChars = /^[a-zA-Z0-9 àèáéíóúÁÈÉÍÓÚñÑüÜ.,;òùì*£$%&]+$/;
  const sanitizedInput = strippedInput.match(allowedChars)?.join("") ?? null;

  return sanitizedInput;
};

const sanitizeCustomParams = (config) => {
  const sanitizedParams = {};

  // Loop through config keys
  for (const key in config) {
    if (key.startsWith("CUSTOM_")) {
      // Sanitize value of custom params
      const sanitizedValue = sanitizeValue(config[key]);

      // Add sanitized key-value pair to newObj
      sanitizedParams[key] = sanitizedValue;
    }
  }
  return sanitizedParams;
};

const getDevice = async ({ config }) => {
  let did;
  let secret;

  logger.log("getDevice", { config });

  if (config && config.userId && config.userHash) {
    logger.log("Hi loaded userId and userHash from config");

    const userIdHash = await _digestMessage(config.userId);
    const getDidFromHash = (h) => {
      const did =
        h.substring(0, 8) +
        "-" +
        h.substring(8, 12) +
        "-" +
        h.substring(12, 16) +
        "-" +
        h.substring(16, 20) +
        "-" +
        h.substring(20, 32);
      return did;
    };

    return {
      did: getDidFromHash(userIdHash),
      secret: `24-${config.userHash}`,
      provisioningTemplateName: config.customerId || "demo",
      locale: ["it-IT", "en-GB", "fr-FR"].includes(config.language)
        ? config.language
        : null,
    };
  }

  if (config && config.userId && !config.userHash) {
    throw new Error(
      "Hi: you must provide both userId and userHash in call to init()"
    );
  }

  // else, normal flow: we generate a new did and secret
  // and save it in localStorage

  try {
    ({ did, secret } = JSON.parse(localStorage.getItem("HI::device")));
  } catch (e) {
  } finally {
    if (!did || !secret) {
      if (!did) {
        did = uuidv4();
      }

      if (!secret) {
        secret = `24-${uuidv4()}`;
      }

      localStorage.setItem("HI::device", JSON.stringify({ did, secret }));
    }
  }

  return {
    did,
    secret,
    provisioningTemplateName: config.customerId || "demo",
    locale: config.language || null,
  };
};

const getDashboardElement = () => document.querySelector("#hi-dashboard");
const getDashboardContainerElement = () =>
  document.querySelector("#hi-container");

//Helper function to wait for element to be loaded.
//It ll make 3 attemps to get the element (one per second).
//If found: Return element, else throw error.
const waitForElementToLoad = (selector) => {
  return new Promise((resolve, reject) => {
    let attempts = 0;
    let intervalId = setInterval(() => {
      attempts++;
      let element = document.querySelector(selector);
      if (element) {
        clearInterval(intervalId);
        resolve(element);
      } else if (attempts >= 3) {
        clearInterval(intervalId);
        console.error(`Element ${selector} not found`);
        reject(new Error(`Element ${selector} not found`));
      }
    }, 1000);
  });
};

const renderDashboard = ({ did, path = "/", data }) => {
  let dashboard = getDashboardElement();
  const regex = /did=/;
  let src = "";
  if (regex.test(path)) {
    src = `https://userdashboard.${process.env.DOMAIN_NAME}${path}`;
  } else {
    src = `https://userdashboard.${process.env.DOMAIN_NAME}${path}?did=${did}`;
  }

  if (dashboard) {
    const iframe = document.querySelector("#hi-dashboard iframe");
    iframe.setAttribute("src", src);
    return;
  }

  if (data.dashboardAccess === "inside-app") {
    waitForElementToLoad("#hi-container")
      .then((element) => {
        let dashboardContainer = element;
        dashboard = document.createElement("div");
        dashboard.id = "hi-dashboard";
        dashboard.innerHTML = `<iframe frameborder="0" src="${src}" />`;
        dashboardContainer.innerHTML = "";
        dashboardContainer.appendChild(dashboard);
      })
      .catch((error) => {
        console.error(error);
      });
  } else {
    let dashboardContainer = document.createElement("div");
    const blurEffectContainer = document.createElement("div");
    blurEffectContainer.id = "blur-effect";
    dashboardContainer.id = "hi-container";
    dashboardContainer.appendChild(blurEffectContainer);
    dashboard = document.createElement("div");
    dashboard.id = "hi-dashboard";
    dashboard.innerHTML = `<iframe frameborder="0" src="${src}" />`;
    dashboardContainer.appendChild(dashboard);
    document.body.appendChild(dashboardContainer);
    dashboardContainer.appendChild(dashboard);
    document.body.appendChild(dashboardContainer);
  }
};

const renderHI = ({ did, data }) => {
  let root = document.querySelector("#icon-container");
  appInitData = data;
  if (root) {
    return;
  }

  const styles = document.createElement("style");
  styles.innerHTML = `
    #icon-container {
      position: fixed;
      bottom: 11px;
      right: 11px;
      z-index: ${Number.MAX_SAFE_INTEGER};

      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      
    }
     #hi-stimulus {
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: ${Number.MAX_SAFE_INTEGER};
    }`;

  root = document.createElement("div");
  root.id = "icon-container";
  if (data.dashboardAccess === "inside-app") {
    checkForDashboard({ did });
  } else {
    styles.innerHTML =
      styles.innerHTML +
      `#hi-icon {
      cursor: pointer;
      border-radius: 12px;
      background: #241751;
      width: 38px;
      height: 38px;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #hi-container {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10000;
    }

    #blur-effect {
      backdrop-filter: blur(10px);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }

    #hi-dashboard {
      z-index: ${Number.MAX_SAFE_INTEGER};
      width: 90%;
      max-width: 1242.5px;
      height: 90%;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 18px;
      border: 2px solid  #241751;
      background-color: rgb(249, 249, 249);
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
      overflow: hidden;
    }
    
    #hi-dashboard iframe {
      width: 100%;
      height: 100%;
    }
    
    .hi-web-custom-btn-icon {
      cursor: pointer;
      width: 100%;
      height: 100%;
    }     
    
    `;
    if (data.hiWebBtnIcon) {
      root.innerHTML = `
      <img class="hi-web-custom-btn-icon" src= ${data.hiWebBtnIcon}>`;
    } else {
      root.innerHTML = `
     <div id="hi-icon">
      <svg width="18" height="18" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M28.093 7.233c1.012 0 1.863-.347 2.551-1.042.718-.71 1.076-1.553 1.076-2.532a3.487 3.487 0 0 0-1.076-2.574C29.927.362 29.077 0 28.093 0c-.97 0-1.807.362-2.51 1.085-.69.695-1.034 1.553-1.034 2.574 0 .993.345 1.837 1.034 2.532.689.695 1.525 1.042 2.51 1.042ZM5.694 31.72V20.615c0-1.73.507-3.113 1.519-4.149 1.04-1.05 2.264-1.574 3.67-1.574 1.279 0 2.274.355 2.984 1.064.71.709 1.065 1.751 1.065 3.127V31.72h5.715V17.04c0-2.453-.703-4.304-2.109-5.552-1.42-1.262-3.31-1.893-5.673-1.893a9.714 9.714 0 0 0-4.07.893c-1.308.596-2.341 1.383-3.1 2.362V.34H0v31.38h5.694Zm25.288-21.53v21.53h-5.695V10.19h5.695Z" fill="#94f1b2"/></svg>
     </div>`;
    }

    root.addEventListener("click", () => {
      const dashboardcontainer = getDashboardContainerElement();
      if (!dashboardcontainer) {
        renderDashboard({ did, data: appInitData });
      } else {
        removeDashboardContainer();
      }
    });

    window.addEventListener("click", (e) => {
      const dashboard = getDashboardElement();
      if (root?.contains(e.target) || dashboard?.contains(e.target)) {
        return;
      }
      removeDashboardContainer();
    });
  }
  document.body.appendChild(styles);
  document.body.appendChild(root);
};

const removeDashboardContainer = () => {
  const dashboardcontainer = getDashboardContainerElement();
  if (dashboardcontainer) {
    dashboardcontainer.remove();
  }
};

const handleMessageEvent = (e, did) => {
  windowMessageEvtListener = true;
  try {
    const { type, width, height, url } = e.data;

    if (type === "rendered") {
      const hiStimulus = document.querySelector("#hi-stimulus");
      hiStimulus.style.width = width + "px";
      hiStimulus.style.height = height + "px";
      const iframe = document.querySelector("#hi-stimulus iframe");
      iframe.setAttribute("width", width);
      iframe.setAttribute("height", height);
      return;
    }

    if (type === "closed") {
      const iframe = document.querySelector("#hi-stimulus iframe");
      iframe?.parentNode?.remove();
      removeNotification();
      const iconContainer = document.getElementById("icon-container");
      iconContainer ? (iconContainer.style.visibility = "visible") : null;
      return;
    }

    if (type === "openURL") {
      const regexp = new RegExp(
        `^https?:\/\/userdashboard\\.${process.env.DOMAIN_NAME.replace(
          ".",
          "\\."
        )}`
      );
      if (url.startsWith("hicoach://dashboard") || url.match(regexp)) {
        const path = url.replace("hicoach://dashboard", "").replace(regexp, "");

        if (!getDashboardContainerElement()) {
          // Get the element by its ID
          const myElement = document.getElementById(
            window.HiConfig.customButonId
          );

          if (myElement) {
            // Click the element
            myElement.click();
          } else {
            renderDashboard({ did, path, data: appInitData });
            return;
          }
        }
        renderDashboard({ did, path, data: appInitData });
        return;
      }

      window.open(url, "_blank");
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
};
const renderStimulus = ({ did, templateUrl, size, target }) => {
  let stimulus = document.querySelector("#hi-stimulus");
  showNotification();
  if (stimulus) {
    stimulus.remove();
  }

  stimulus = document.createElement("div");
  stimulus.id = "hi-stimulus";
  stimulus.innerHTML = `<iframe frameborder="0" src="${templateUrl}" width="${size.width}" height="${size.height}" />`;
  stimulus.addEventListener("click", function (event) {
    // Prevent the event from bubbling up to the parent elements
    event.stopPropagation();
  });
  // if (target) {

  //   const { x, y, height } = target.getBoundingClientRect();
  //   stimulus.style.top = `${y + height + 20}px`;
  //   stimulus.style.left = `${x}px`;
  // }
  const iconContainer = document.getElementById("icon-container");
  iconContainer ? (iconContainer.style.visibility = "hidden") : null;
  document.body.appendChild(stimulus);

  !windowMessageEvtListener &&
    window.addEventListener("message", (e) => handleMessageEvent(e, did));

  // window.addEventListener("message", (e) => {
  //   try {
  //     const { type, width, height, url } = e.data;

  //     if (type === "rendered") {
  //       const hiStimulus = document.querySelector("#hi-stimulus");
  //       hiStimulus.style.width = width + "px";
  //       hiStimulus.style.height = height + "px";
  //       const iframe = document.querySelector("#hi-stimulus iframe");
  //       iframe.setAttribute("width", width);
  //       iframe.setAttribute("height", height);
  //       return;
  //     }

  //     if (type === "closed") {
  //       const iframe = document.querySelector("#hi-stimulus iframe");
  //       iframe?.parentNode?.remove();
  //       removeNotification();
  //       const iconContainer = document.getElementById("icon-container");
  //       iconContainer ? (iconContainer.style.visibility = "visible") : null;
  //       return;
  //     }

  //     if (type === "openURL") {
  //       const regexp = new RegExp(
  //         `^https?:\/\/userdashboard\\.${process.env.DOMAIN_NAME.replace(
  //           ".",
  //           "\\."
  //         )}`
  //       );
  //       if (url.startsWith("hicoach://dashboard") || url.match(regexp)) {
  //         const path = url
  //           .replace("hicoach://dashboard", "")
  //           .replace(regexp, "");

  //         if (!getDashboardContainerElement()) {
  //           // Get the element by its ID
  //           const myElement = document.getElementById(
  //             window.HiConfig.customButonId
  //           );

  //           if (myElement) {
  //             // Click the element
  //             myElement.click();
  //           } else {
  //             renderDashboard({ did, path, data: appInitData });
  //             return;
  //           }
  //         }
  //         renderDashboard({ did, path, data: appInitData });
  //         return;
  //       }

  //       window.open(url, "_blank");
  //     }
  //   } catch (e) {}
  // });
};

const initTriggers = ({ did }) => {
  const triggers = [
    {
      selector: 'input[hi-web-id="semantics-search"]',
      event: "input",
      selectorName: "ContentDashboardSemanticsSearch",
    },
    {
      selector: 'button[hi-web-id="semantics-export-button"]',
      event: "click",
      selectorName: "ContentDashboardSemanticsExport",
    },
    {
      selector: 'button[data-hi_id="subscribeButton"]',
      event: "click",
      selectorName: "SapSubscribe",
    },
    {
      selector: 'bdi[id="__label0-bdi"]',
      event: "mouseenter",
      selectorName: "SapNewEventsLabel",
    },
    {
      selector: 'span[id="__box0-arrow"]',
      event: "click",
      selectorName: "SapDropdownRelationToSap",
    },
    {
      selector: 'p[title="Contact Owner"]',
      event: "presence",
      selectorName: "SalesforceContactPage",
    },
    {
      selector: "span.global-nav__primary-link-text",
      event: "presence",
      selectorName: "LinkedinAnyPage",
    },
    {
      selector: 'img[id="hi-risorse"]',
      event: "presence",
      selectorName: "WebsiteRisorseTab",
    },
    {
      selector: 'img[id="hi-piattaforma"]',
      event: "presence",
      selectorName: "WebsitePiattaformaTab",
    },
    {
      selector: 'img[id="hi-home"]',
      event: "presence",
      selectorName: "WebsiteHomeTab",
    },
    {
      selector: 'h1[hi-trigger="marketplace"]',
      event: "presence",
      selectorName: "AdvMarketplace",
    },
    {
      selector: 'h1[hi-trigger="news"]',
      event: "presence",
      selectorName: "AdvNews",
    },
    {
      selector: 'h1[hi-trigger="magazine"]',
      event: "presence",
      selectorName: "AdvMagazine",
    },
    {
      selector: 'h1[hi-trigger="insight"]',
      event: "presence",
      selectorName: "AdvInsight",
    },
    {
      selector: 'h1[hi-trigger="events"]',
      event: "presence",
      selectorName: "AdvEvents",
    },
    {
      selector: 'h1[hi-trigger="assessment"]',
      event: "presence",
      selectorName: "AdvAssessment",
    },
    {
      selector: 'h1[hi-trigger="NuovaCampagna"]',
      event: "presence",
      selectorName: "AdvNuovaCampagna",
    },
    {
      selector: 'h1[hi-trigger="template"]',
      event: "presence",
      selectorName: "AdvTemplate",
    },
    {
      selector: 'h1[hi-trigger="deliverable"]',
      event: "presence",
      selectorName: "AdvDeliverable",
    },
    {
      selector: 'h1[hi-trigger="education"]',
      event: "presence",
      selectorName: "AdvEducation",
    },
    {
      selector: 'h1[hi-trigger="users"]',
      event: "presence",
      selectorName: "AdvUtenti",
    },
    {
      selector: 'h1[hi-trigger="aziende"]',
      event: "presence",
      selectorName: "AdvAziende",
    },
    {
      selector: 'h1[hi-trigger="cluster"]',
      event: "presence",
      selectorName: "AdvCluster",
    },
    {
      selector: 'h1[hi-trigger="newsletter"]',
      event: "presence",
      selectorName: "AdvNewsletter",
    },
    {
      selector: 'h1[hi-trigger="dashboard"]',
      event: "presence",
      selectorName: "AdvDashboard",
    },
    {
      selector: 'h1[hi-trigger="search"]',
      event: "presence",
      selectorName: "AdvRicercaTrasversale",
    },
    {
      selector: 'h1[hi-trigger="PreferenzeUtente"]',
      event: "presence",
      selectorName: "AdvPreferenzeUtente",
    },
    {
      selector: 'button[data-automation-id="sp-comment-post"]',
      event: "click",
      selectorName: "SpPostComment",
    }
  ].map((trigger) => ({
    ...trigger,
    listener: debounce((e) => {
      selectorNameElementMap[trigger.selectorName] = e.target;
      sendMessage(did, {
        type: "ElementFound",
        selectorName: trigger.selectorName,
      });
    }, 500),
  }));

  // list of selectornames that already triggered
  // the presence selector:
  const presenceTriggered = [];

  const observer = new MutationObserver(
    debounce(() => {
      triggers.forEach(({ selector, event, selectorName, listener }) => {
        const element = document.querySelector(selector);

        if (element && event === "presence") {
          if (presenceTriggered.includes(selectorName)) {
            // selector already matched
            return;
          }

          selectorNameElementMap[selectorName] = element;
          sendMessage(did, {
            type: "ElementFound",
            selectorName,
          });

          presenceTriggered.push(selectorName);
          return;
        }

        element?.removeEventListener(event, listener);
        element?.addEventListener(event, listener, true);
      });
    })
  );

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
};

const handleMessage = async (data, did) => {
  logger.info("Brain response received", { data, did });
  let firstActivityInterval;
  if (data.type === "app-init-r") {
    if (data.channel === "embedded") {
      initTriggers({ did });
      renderHI({ did, data });

      if (data.dashboardAccess === "inside-app") {
        checkForDashboard({ did });
        if (getDashboardContainerElement()) {
          renderDashboard({ did, data: appInitData });
        }
      }
      sendMessage(did, {
        type: "FirstActivity",
      });

      if (firstActivityInterval) {
        clearInterval(firstActivityInterval);
      }

      firstActivityInterval = setInterval(
        () =>
          sendMessage(did, {
            type: "FirstActivity",
          }),
        60 * 60 * 1000
      );
    }

    return;
  }

  if (data.type !== "dp-r" || !data.payload2) {
    return;
  }

  const { templateUrl, size, selectorName } = data.payload2;

  if (!templateUrl) {
    return;
  }

  renderStimulus({
    did,
    templateUrl,
    size,
    target: selectorNameElementMap[selectorName],
  });

  if (templateUrl.includes("scenario=fromLearningMaterial")) {
    removeDashboardContainer();
  }
};

const sendMessage = (did, payload) => {
  const message = {
    did,
    eid: uuidv4(),
    t: Date.now(),
    type: "dp",
    v: 3,
    p: payload,
  };

  if (socket.readyState !== 1) {
    return;
  }

  logger.info("Send message => ", message);
  socket.send(JSON.stringify(message));
};

const connect = async ({ config, appInit = false }) => {
  const { did, provisioningTemplateName, secret, locale } = await getDevice({
    config,
  });

  const token = btoa(`${did}:${secret}`);
  socket = new WebSocket(
    `wss://wss.${process.env.DOMAIN_NAME}?authorization=${token}`
  );

  socket.addEventListener("open", () => {
    if (appInit) {
      logger.info("Send AppInit event");
      const customParams = sanitizeCustomParams(config);
      sendMessage(did, {
        type: "AppInit",
        version: `hi-web-${process.env.VERSION}`,
        provisioningTemplateName,
        locale,
        ...customParams,
      });
    }
  });

  socket.addEventListener("close", (e) => {
    logger.info("Socket closed =>", e.reason);
    setTimeout(() => {
      connect({ config });
    }, 1000);
  });

  socket.addEventListener("error", (e) => {
    logger.error("Socket error => ", e.message);
    socket.close();
  });

  socket.addEventListener("message", async (event) => {
    logger.groupCollapsed("Socket received message");
    logger.info("Raw => ", event.data);
    const data = JSON.parse(event.data);
    logger.info("Parsed => ", data);
    logger.groupEnd();

    if (!(await isSignatureValid(data))) {
      logger.error("Message signature is not valid");
      return;
    }

    await handleMessage(data, did);
  });
};

//Helper function to listen for changes in the DOM
//If the "hi-container" element is found, then render the dashboard
const checkForDashboard = ({ did }) => {
  // Select the node that will be observed for mutations
  const targetNode = document.body;

  // Options for the observer (which mutations to observe)
  const config = { childList: true, subtree: true };

  // Callback function to execute when mutations are observed
  const callback = function (mutationsList, observer) {
    for (const mutation of mutationsList) {
      if (mutation.type === "childList") {
        for (const addedNode of mutation.addedNodes) {
          if (
            addedNode.nodeType === Node.ELEMENT_NODE &&
            addedNode.id === "hi-container"
          ) {
            renderDashboard({ did, data: appInitData });
          }
        }
      }
    }
  };

  const observer = new MutationObserver(callback);
  observer.observe(targetNode, config);
};

const handleVisibilityChange = () => {
  if (document.visibilityState === "visible") {
    socket.close();
  }
};

// Function to show the notification
const showNotification = () => {
  if (window.HiConfig.showNotification) {
    let favicon = document.getElementById(window.HiConfig.iconId);
    if (window.HiConfig.showBadge && favicon) {
      favIconUrl = favicon.href;
      let faviconSize = 16;
      let canvas = document.createElement("canvas");
      canvas.width = faviconSize;
      canvas.height = faviconSize;

      let context = canvas.getContext("2d");
      let img = document.createElement("img");
      img.src = favicon.href;

      img.onload = () => {
        // Draw Original Favicon as Background
        context.drawImage(img, 0, 0, faviconSize, faviconSize);

        // Draw Notification Circle
        context.beginPath();
        context.arc(
          canvas.width - faviconSize / 3,
          faviconSize / 3,
          faviconSize / 5, //change this number to change the size of the circle
          0,
          2 * Math.PI
        );
        context.fillStyle = "#FF0000";
        context.fill();

        // Replace favicon
        favicon.href = canvas.toDataURL("image/png");
      };
    } else {
      let title = document.querySelector("title");
      title.classList.add("notification-title");
      title.innerText = "(1) " + title.innerText;
      document.title = title.innerText;
    }
  }
};

// Function to remove the notification
const removeNotification = () => {
  if (window.HiConfig.showNotification) {
    let favicon = document.getElementById(window.HiConfig.iconId);
    if (window.HiConfig.showBadge && favicon) {
      favicon.href = favIconUrl;
    } else {
      let title = document.querySelector("title");
      title.classList.remove("notification-title");
      title.innerText = title.innerText.replace("(1) ", "");
      document.title = title.innerText;
    }
  }
};

const init = () => {
  // Check if screen width is greater than 884px
  if (screen.width > 884) {
    // Load the script only if screen width is greater than 884px
    const config = window.HiConfig || {};
    connect({ config, appInit: true })
      .then(() => {
        logger.log("Hi: Connected");
        document.addEventListener("visibilitychange", handleVisibilityChange);
      })
      .catch((e) => {
        logger.error("Hi: Error", e);
      });
  }
};

window.Hi = {
  init,
};
